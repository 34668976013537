.event-overview .events{
    display: flex;
    justify-content: left;
    align-items: stretch;
    flex-wrap: wrap;
    padding: 2vw;
}

.event-overview .events .event{
    box-sizing: border-box;
    display: block;
    text-align: left;
    width: 16.2%;
    background: rgba(255, 255, 255, 0.07);
    box-shadow: 0px 0px 4px 2px rgba(0,0,0,0.1);
    padding: 0.8vw;
    color: black;
    margin: 1% 0.2%;
    border-radius: 0.8vh;
}

.event-overview .events .event .event-name{
    margin: 0.6vw 0;
    color: white;
}

.event-overview .events .event .event-description{
    margin: 0.6vw 0;
    color: white;
    font-size: 0.7em;
}

.event-overview .events .event .count{
    float: left;
    width: 2vw;
    height: 2vw;
    line-height: 2vw;
    border-radius: 1vh;
    margin-right: 0.5vw;
    text-align: center;
    background: rgba(11,214,196,1);
    color: white;
}
.event-overview .events .event .count.accept{
    background: rgba(10,204,114,1);
}
.event-overview .events .event .count.reject{
    background: rgba(251,57,69,1);
}

.event-overview .events .event img{
    width: 100%;
    box-sizing: border-box;
    background: black;
}

.event-overview .events .event .overlay{
    box-sizing: border-box;
    position: fixed;
    width: 100vw;
    height: 100vh;
    padding: 0 3vw;
    left: 0;
    top: 0;
    background: rgba(0,0,0,0.5);
}

.event-overview .events .event .dialog{
    display: table;
    position: relative;
    min-width: 35%;
    background: #006A52;
    padding: 2vh;
    margin-left: auto;
    margin-right: auto;
    margin-top: 4vh;
    color: white;
    border-radius: 1vh;
}

.event-overview .events .event .dialog .clearfix{
    float: none;
    display: block;
}

.event-overview .events .event .dialog .float{
    float: left;
    max-width: 80%;
}
.event-overview .events .event .dialog .float-right{
    float:right;
}

.event-overview .events .event .dialog .event-image{
    right: 3vh;
    top: 3vh;
    max-width: 10vw;
}


.event-overview .events .event .dialog h2{
}

.event-overview .events .event .dialog p{
    font-size: 1em;
}

.event-overview .events .event .invitees{
    overflow: auto;
    max-height: 18vh;
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
}

.event-overview .events .event .invitees .invitee{
    position: relative;
    width: 3.7vw;
    margin-right: 0.8vw;
    margin-bottom: 1vw;
}

.event-overview .events .event .invitees .invitee img{
    width: 100%;
    border-radius: 100%;
}

.event-overview .events .event .invitees .invitee .invitee-name{
    position: absolute;
    font-size: 0.45vw;
    line-height: 0.45vw;
    bottom: -0.8vh;
    text-align: center;
    left: -20%;
    right: -20%;
}

.event-overview .events .event .invitees .invitee .invitee-menu{
    position: absolute;
    color: white;
    width: 10vw;
    height: 5vw;
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    top: -25%;
    left: 0;
    z-index: 10;
}

.event-overview .events .event .invitees .invitee .invitee-menu .accept-image{
    position: absolute;
    background: none;
    border-radius: 0;
    left: 31%;
    top: 0;
    width: 10vw;
}

.event-overview .events .event .invitees .invitee .invitee-menu .reject-image{
    position: absolute;
    background: none;
    width: 10vw;
    border-radius: 0;
    left: 19%;
    top: 71%;
}

.event-overview .events .event .invitees .invitee .invitee-menu .accept-text{
    position: absolute;
    top: 16%;
    left: 63%;
    width: 63%;
    text-align: right;
    font-size: 1.2vw;
    text-shadow: 1px 1px 2px black;
}
.event-overview .events .event .invitees .invitee .invitee-menu .reject-text{
    position: absolute;
    font-size: 1.2vw;
    left: 42%;
    top: 110%;
    width: 70%;
    text-align: right;
    text-shadow: 1px 1px 2px black;
}


.event-overview hr{
    margin-top: 3vw;
    border-style: solid;
    border-color: white;
}