.news-slide{
    margin: 0 1vw;
   
    height: calc(9 * 1vw);
    width: calc(16 * 1vw); 
  
  
    bottom: 1.5vw;
    overflow: hidden;
    border-radius: 00.5vh;

}

.news-slide-lorry{
    position: relative;
    box-sizing: border-box;
    background: rgba(255,255,255,0.3);
    color: black;
    padding: 1.2vh;
    height: 16vh;

    text-overflow: ellipsis;
    overflow: hidden;

}

.news-slide-lorry h3{
    float: left;
    text-align: left;
    font-size: 1.2vh;
    font-weight:bolder;
    line-height: 1.3vh;
    height: 4.6vh;
    margin-top: 0;
    margin-left: 0;
    padding-right: 0 ;
    margin-bottom: 0;
    
    width: 72%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.news-slide .bodytext {
    float: left;
    font-size: 1.1vh;
    line-height: 1.2vh;
    max-width: 50%;
    max-height: 70%;
    box-sizing: border-box;
    margin: 0;
    padding: 0 1vh 0;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;

    
}

.news-slide-lorry p.full-width{
    max-width: 100%;
    padding: 0;
}

.news-slide-lorry p a{
    text-decoration: none;
}

.news-slide-lorry .article-img{
    max-width: 50%;
    max-height: 70%;
    float: left;
}

.news-slide-lorry .logo {
    position: absolute;
    width: 20%;
    top: 1vh;
    right: 1vh;
    box-sizing: border-box;
}